<template>
  <div
    ref="sectionRef"
    class="relative flex h-screen min-h-[600px] items-center justify-center overflow-hidden text-center lg:min-h-[760px]"
  >
    <div ref="heroRef" class="overflow-hidden">
      <Heading
        is-hero-heading
        class="mx-auto max-w-3xl px-4 text-center text-5xl font-light text-blue lg:mb-8 lg:px-0 lg:text-9xl"
        :class="data.html ? 'mb-3' : 'mb-8'"
        @completed="heroAnimated = true"
      >
        {{ data.title }}
      </Heading>
      <div
        v-if="data.html"
        class="mx-auto mb-8 max-w-3xl px-4 text-base transition-opacity duration-300 lg:px-0 lg:text-xl"
        :class="heroAnimated ? 'lg:opacity-100' : 'lg:opacity-0'"
        v-html="data.html"
      ></div>

      <CallToAction
        v-if="Array.isArray(data.buttons) && data.buttons.length"
        :data="data.buttons"
        :childClass="'mt-3 ml-0 lg:ml-4'"
        class="mx-auto flex-col px-4 transition-opacity duration-300 lg:flex-row lg:px-0"
        :class="[data.media ? '' : 'pb-20', heroAnimated ? 'lg:opacity-100' : 'lg:opacity-0']"
      />
      <div v-if="data.media" class="mx-auto max-w-7xl pt-10">
        <Image
          class="aspect-video h-[650px] object-cover object-center"
          :src="data.media.responsiveImage ? data.media.responsiveImage?.src : data.media.url"
          :srcset="data.media.responsiveImage ? data.media.responsiveImage?.webpSrcSet : undefined"
          :width="data.media.responsiveImage ? data.media.responsiveImage?.width : data.media.width"
          :height="
            data.media.responsiveImage ? data.media.responsiveImage?.height : data.media.height
          "
          :alt="data.media?.alt!"
          sizes="(min-width: 1400px) 1280px, 93.7vw"
        />
      </div>
    </div>
    <HeroTransition v-if="isDesktop" class="hidden lg:block" :hasMedia="!!data.media" />
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useAppStore } from '~/stores/AppStore'
import type { SectionHeroRecord } from '~/types/generated'
import { theme } from '#tailwind-config'

const breakpoints = useBreakpoints(theme.screens)
const { isDesktop } = useDevice()
const isScreenLg = breakpoints.greater('lg')
const store = useAppStore()
const heroRef = ref<HTMLElement | null>(null)
const sectionRef = ref<HTMLElement | null>(null)
const heroAnimated = ref(false)

defineProps({
  data: {
    type: Object as PropType<SectionHeroRecord>,
    default: () => {}
  }
})

onMounted(() => {
  store.setTheme('white')

  gsap.registerPlugin(ScrollTrigger)

  if (heroRef.value) {
    const tlHero = gsap.timeline({
      scrollTrigger: {
        trigger: heroRef.value,
        start: 'top 5%',
        scrub: true
      }
    })
    // fade out hero
    tlHero.to(heroRef.value, {
      y: -100,
      opacity: 0,
      duration: 1
    })
  }

  if (sectionRef.value && isScreenLg.value) {
    gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.value,
        pin: true,
        start: 'top top',
        end: 'bottom+=1000px center',
        scrub: true
      }
    })
  }
})

onUnmounted(() => {
  ScrollTrigger.getAll().forEach(trigger => {
    trigger.kill()
  })
})
</script>
