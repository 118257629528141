<template>
  <div v-show="isMounted" ref="containerRef" class="h-screen min-h-[760px]">
    <div ref="ImageRef1" class="absolute left-0 top-[2%] z-2 h-[30%] w-[60vw]">
      <Image
        loading="lazy"
        class="h-full w-full object-cover"
        effect="none"
        :src="Image1"
        alt="Hero Image First"
        :width="1230"
        :height="514"
      />
    </div>
    <div ref="ImageRef2" class="absolute right-0 top-[2%] h-[30%] w-[60vw]">
      <Image
        loading="lazy"
        class="h-full w-full object-cover"
        effect="none"
        :src="Image2"
        alt="Hero Image Second"
        :width="1230"
        :height="514"
      />
    </div>
    <div ref="ImageRef3" class="absolute left-0 top-[35%] z-2 h-[30%] w-[60vw]">
      <Image
        loading="lazy"
        class="h-full w-full object-cover"
        effect="none"
        :src="Image3"
        alt="Hero Image Third"
        :width="1230"
        :height="514"
      />
    </div>
    <div ref="ImageRef4" class="absolute right-0 top-[35%] h-[30%] w-[60vw]">
      <Image
        loading="lazy"
        class="h-full w-full object-cover"
        effect="none"
        :src="Image4"
        alt="Hero Image Fourth"
        :width="1230"
        :height="514"
      />
    </div>
    <div ref="ImageRef5" class="absolute bottom-[2%] left-0 z-2 h-[30%] w-[60vw]">
      <Image
        loading="lazy"
        class="h-full w-full object-cover"
        effect="none"
        :src="Image5"
        alt="Hero Image Fifth"
        :width="1230"
        :height="514"
      />
    </div>
    <div ref="ImageRef6" class="absolute bottom-[2%] right-0 h-[30%] w-[60vw]">
      <Image
        ref="ImageRef6"
        loading="lazy"
        class="h-full w-full object-cover"
        effect="none"
        :src="Image6"
        alt="Hero Image Sixth"
        :width="1230"
        :height="514"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Image1 from '~/assets/images/interactive/Image1.webp'
import Image2 from '~/assets/images/interactive/Image2.webp'
import Image3 from '~/assets/images/interactive/Image3.webp'
import Image4 from '~/assets/images/interactive/Image4.webp'
import Image5 from '~/assets/images/interactive/Image5.webp'
import Image6 from '~/assets/images/interactive/Image6.webp'

const containerRef = ref<HTMLElement | null>(null)
const ImageRef1 = ref<HTMLElement | null>(null)
const ImageRef2 = ref<HTMLElement | null>(null)
const ImageRef3 = ref<HTMLElement | null>(null)
const ImageRef4 = ref<HTMLElement | null>(null)
const ImageRef5 = ref<HTMLElement | null>(null)
const ImageRef6 = ref<HTMLElement | null>(null)

const imageRefs = [ImageRef1, ImageRef2, ImageRef3, ImageRef4, ImageRef5, ImageRef6]

const isMounted = ref(false)

defineProps({
  hasMedia: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
  isMounted.value = true

  gsap.registerPlugin(ScrollTrigger)

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: containerRef.value,
      start: 'top-=100px top',
      end: 'bottom+=1000px center',
      scrub: true
    }
  })

  for (let i = 1; i <= 6; i++) {
    const imageRef = imageRefs[i - 1]

    tl.from(imageRef.value, {
      x: i % 2 !== 0 ? '-100vw' : '100vw',
      left: i % 2 !== 0 ? '-200px' : 'auto',
      duration: 10
    })
  }
})

onUnmounted(() => {
  ScrollTrigger.getAll().forEach(trigger => {
    trigger.kill()
  })
})
</script>
